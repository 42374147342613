<template>
    <tr :class="[!editable ? 'no-access' : '']">
        <td><icon v-if="!editable" type="lock" size="small" /> {{ field.name }}</td>
        <td>
            <div v-if="currentlyEditing && editable">
                <component
                        @save="updateValue"
                        :value="metaField[field.name]"
                        :is="selectedEditComponent"
                        :label="field.name"
                />
            </div>
            <div :class="[!metaField[field.name] ? 'table-filler' : '']" v-else @click="startEditing(field.name, field.type)">
                {{ metaField[field.name] }}
            </div>
        </td>
    </tr>
</template>

<script>
    import TextWidget from "../editors/TextWidget";
    import IntegerWidget from "../editors/IntegerWidget";
    import BooleanEditor from "../editors/BooleanEditor";
    import Icon from "../../Icon";
    import Codemirror from "@/components/widgets/editors/Codemirror";
    export default {
        name: "MetaFieldEdit",
        components: {
            TextWidget,
            Codemirror,
            IntegerWidget,
            BooleanEditor,
            Icon,
        },
        props: {
            field: {type: Object, required: true},
            metaField: {type: Object, required: true},
            metaSetId: {type: String, required: true},
            editable: {type: Boolean, default: true},
        },
        data() {
            return {
                currentlyEditing: false,
                selectedEditComponent: '',
            };
        },
        methods: {
            startEditing(fieldName, fieldType) {
                this.currentlyEditing = true;
                switch (fieldType) {
                    case 'json':
                        this.selectedEditComponent = 'codemirror';
                        break;
                    case 'number':
                        this.selectedEditComponent = 'integer-widget';
                        break;
                    case 'boolean':
                        this.selectedEditComponent = 'boolean-editor';
                        break;
                    default:
                        this.selectedEditComponent = 'text-widget';
                }
            },
            cancelEditing() {
                this.currentlyEditing = false;
            },
            updateValue(value) {
                let temp = JSON.parse(JSON.stringify(this.metaField));
                temp[this.field.name] = value;
                this.$store.dispatch('updateMetaField', {
                    id: this.metaSetId,
                    cid: this.metaField.id,
                    args: temp,
                    listName: this.metaSetId,
                });
                this.cancelEditing();
            },
        }
    }
</script>

<style scoped>
  .table-filler {
    min-height:30px;
    padding:5px;
    background-color: rgba(0,0,0,0.5);
  }
</style>