<template>
    <div>
        <div class="lighter">ID: {{ metaField.id }}</div>
        <table class="mb-5">
            <meta-meta-field-row
                    :key="index" v-for="(field, index) in editableFields" :class="[index%2 === 0 ? 'even' : 'odd']"
                    :field="field"
                    :meta-field="metaField"
                    :meta-set-id="metaSetId"
                    :editable="!field.alwaysLocked && ((!hasNoMetaValueData && !field.lockWheninUse) || hasNoMetaValueData)"
            />
        </table>
        <Button
                v-if="hasNoMetaValueData"
                class="mt-2"
                type="delete-button"
                @click="deleteMetaField"
                icon="times">
            {{ $t('delete') }}
        </Button>
    </div>
</template>

<script>
    import Button from "../../forms/Button";
    import MetaMetaFieldRow from "@/components/widgets/meta/AdminMetaFieldRow";
    export default {
        name: "MetaFieldEdit",
        components: {
          MetaMetaFieldRow,
          Button,
        },
        props: {
            metaSetId: {type: String, required: true},
            editableFields: {type: Array, required: true},
            metaField: {type: Object, required: true},
        },
        data() {
            return {
                hasNoMetaValueData: true,
            };
        },
        beforeMount() {
            this.$store.dispatch('getMetaValues', {
                id: this.metaSetId,
                cid: this.metaField.id,
                fields: 'id',
                limit: 1
            }).then(data => {
                this.hasNoMetaValueData = (data.length === 0) || !data;
            });
        },
        methods: {
            deleteMetaField: function() {
                this.$store.dispatch('deleteMetaField', {
                    id: this.metaSetId,
                    listName: this.metaSetId,
                    cid: this.metaField.id,
                    args: [this.metaField.id],
                });
            },
        },
    }
</script>

